import React, { ChangeEvent, useEffect, useState } from 'react'
import ReactSelect, { OptionTypeBase, ValueType } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import styled from 'styled-components'
import { cloudinaryFullImage } from '../../lib/cloudinary'
import { Category, GearItem, GearItemPartial, Location, Person, Photo, User } from '../../model'
import { Button, DeleteButton } from '../../components/Button/Button'
import { GearOwnerSelector } from './GearOwnerSelector'
import { imageHeight, ImageView, imageWidth } from '../../components/ImageView/ImageView'
import { Label, LabelTitle, LabelDetail } from '../../components/Label'
import { TextInput } from '../../components/TextInput'
import { TextArea } from '../../components/TextArea'

const Container = styled.div`
  width: 100%;
  max-width: ${props => props.theme.itemPageMaxWidth};
  margin-left: auto;
  margin-right: auto;
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const LoanableText = styled.span`
  cursor: pointer;
  padding-left: ${props => props.theme.spacing(1)};
`

const Buttons = styled.div`
  display: flex;

  button {
    margin-right: ${props => props.theme.spacing(2)};
  }
`

interface FormProps {
  onNew: (item: GearItemPartial, photosToUpload: File[], photosToDelete: string[], categoriesToCreate: string[]) => void
  onSave: (
    item: GearItemPartial,
    photosToUpload: File[],
    photosToDelete: string[],
    categoriesToCreate: string[],
  ) => void
  onDelete: () => void
  item?: GearItem
  categories: Category[]
  locations: Location[]
  people: Person[]
  user: User
  saving: boolean
  error: string | null
  prefilledTag: string | null
}

interface PhotoToUpload {
  file: File
  objectUrl: string
}

interface GeneratedOptions {
  options: OptionTypeBase[]
  selected: OptionTypeBase[]
}

const createCategoriesOptions = (
  allCategories: Category[],
  categories: number[],
  categoriesToCreate: OptionTypeBase[],
): GeneratedOptions => {
  const options = allCategories.map(category => ({ value: category.id, label: category.name }))

  const selectedExistingCategories = categories.map(categoryId => ({
    value: categoryId,
    label: allCategories.find(category => category.id === categoryId)?.name || '',
  })) as any[]

  const selected = selectedExistingCategories.concat(categoriesToCreate)

  return { selected, options }
}

const createContactsOptions = (people: Person[], selectedPersons: string[]): GeneratedOptions => {
  const options = people.map(person => ({ value: person.uid, label: person.name }))

  const selected = selectedPersons.map(selectedPerson => {
    const person = people.find(iteratedPerson => iteratedPerson.uid === selectedPerson)
    const label = person ? person.name : 'Unknown person'
    return {
      value: selectedPerson,
      label,
    }
  })
  return { selected, options }
}

const createLocationOptions = (locations: Location[], selectedLocation: number): GeneratedOptions => {
  const options = locations.map(l => ({ value: l.id, label: l.name }))

  const location = locations.find(l => l.id === selectedLocation)
  const label = location ? location.name : 'Unknown location'
  const selected = [
    {
      value: selectedLocation,
      label,
    },
  ]

  return { selected, options }
}

export const ItemEditForm: React.FC<FormProps> = (props: FormProps) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [tag, setTag] = useState<string | null>(props.prefilledTag || '')
  const [photos, setPhotos] = useState<Photo[]>([])
  const [photosToUpload, setPhotosToUpload] = useState<PhotoToUpload[]>([])
  const [photosToDelete, setPhotosToDelete] = useState<string[]>([])
  const [categories, setCategories] = useState<number[]>([])
  const [contacts, setContacts] = useState<string[]>([])
  const [categoriesToCreate, setCategoriesToCreate] = useState<OptionTypeBase[]>([])
  const [slack, setSlack] = useState<string>('')
  const [location, setLocation] = useState<number>(props.user.defaultLocation.id)
  const [locationInformation, setLocationInformation] = useState<string>('')
  const [personal, setPersonal] = useState<boolean>(false)
  const [loanable, setLoanable] = useState<boolean>(true)

  const [categoryOptions, setCategoryOptions] = useState<GeneratedOptions>({ selected: [], options: [] })
  const [contactsOptions, setContactsOptions] = useState<GeneratedOptions>({ selected: [], options: [] })
  const [locationOptions, setLocationOptions] = useState<GeneratedOptions>({ selected: [], options: [] })

  const createPartialItem = (): GearItemPartial => {
    if (location !== null) {
      return {
        categories,
        contacts,
        description,
        loanable,
        location,
        personal,
        location_information: locationInformation,
        name,
        photos,
        slack,
        tag: tag && tag.length > 0 ? tag : null,
      }
    } else {
      throw Error('locations messed up')
    }
  }

  const onNew = () => {
    props.onNew(
      createPartialItem(),
      photosToUpload.map(p => p.file),
      photosToDelete,
      categoriesToCreate.map(category => category.label),
    )
  }

  const onSave = () => {
    if (props.item) {
      props.onSave(
        createPartialItem(),
        photosToUpload.map(p => p.file),
        photosToDelete,
        categoriesToCreate.map(category => category.label),
      )
    }
  }

  useEffect(() => {
    if (props.item) {
      setCategories(props.item.categories)
      setCategoriesToCreate([])
      setContacts(props.item.contacts.map(contact => contact.uid))
      setDescription(props.item.description)
      setLoanable(props.item.loanable)
      setLocation(props.item.location)
      setLocationInformation(props.item.location_information)
      setPersonal(props.item.personal)
      setPhotos(props.item.photos)
      setPhotosToUpload([])
      setPhotosToDelete([])
      setName(props.item.name)
      setSlack(props.item.slack)
      setTag(props.item.tag)
    } else {
      setCategories([])
      setCategoriesToCreate([])
      setContacts([])
      setDescription('')
      setLoanable(true)
      setLocation(props.user.defaultLocation.id)
      setLocationInformation('')
      setPersonal(false)
      setPhotos([])
      setPhotosToUpload([])
      setPhotosToDelete([])
      setName('')
      setSlack('')
      setTag(props.prefilledTag || '')
    }
  }, [props.item, props.prefilledTag, props.user.defaultLocation])

  useEffect(() => {
    setCategoryOptions(createCategoriesOptions(props.categories, categories, categoriesToCreate))
  }, [props.categories, categories, categoriesToCreate])

  useEffect(() => {
    setContactsOptions(createContactsOptions(props.people, contacts))
  }, [props.people, contacts])

  useEffect(() => {
    setLocationOptions(createLocationOptions(props.locations, location))
  }, [props.locations, location])

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const onTagChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTag(event.target.value)
  }

  const onSlackChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSlack(event.target.value)
  }

  const onDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value)
  }

  const onImageChange = (file: File) => {
    const previewBlobUrl = URL.createObjectURL(file)
    setPhotosToUpload([{ file, objectUrl: previewBlobUrl }])
  }

  const onDeleteImage = () => {
    setPhotosToDelete(photos.map(photo => photo.url))
    setPhotos([])
    setPhotosToUpload([])
  }

  const onCategoriesChange = (value: ValueType<OptionTypeBase, true>) => {
    const values = value !== null ? (value as OptionTypeBase[]) : []
    const existingCategories = values.filter(item => typeof item.value === 'number')
    const newCategories = values.filter(item => typeof item.value !== 'number')

    setCategoriesToCreate(newCategories)
    setCategories(existingCategories.map(category => category.value))
  }

  const onContactsChange = (value: ValueType<OptionTypeBase, true>) => {
    const values = value !== null ? (value as OptionTypeBase[]) : []
    setContacts((values as OptionTypeBase[]).map(v => v.value))
  }

  const onLocationChange = (value: ValueType<OptionTypeBase, false>) => {
    setLocation((value as OptionTypeBase).value)
  }

  const onLocationInformationChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocationInformation(event.target.value)
  }

  const onLoanableChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoanable(event.target.checked)
  }

  const onLoanableTextClick = () => {
    setLoanable(!loanable)
  }

  const generateImageUrl = () => {
    if (photosToUpload.length > 0) {
      return { original: photosToUpload[0].objectUrl, cropped: photosToUpload[0].objectUrl }
    }
    if (photos.length > 0) {
      return { original: photos[0].url, cropped: cloudinaryFullImage(photos[0].url, imageWidth, imageHeight) }
    }
    return { original: undefined, cropped: undefined }
  }

  const onDelete = () => {
    if (props.item && window.confirm(`Delete "${name}"?`)) {
      props.onDelete()
    }
  }

  const imageUrl = generateImageUrl()

  return (
    <Container>
      <ImageView image={imageUrl} onChange={onImageChange} onDelete={onDeleteImage} />
      <GearOwnerSelector personal={personal} onSelect={setPersonal} />
      <Details>
        <Label>
          <LabelTitle>
            <img src="/images/name.svg" alt="" />
            Name
          </LabelTitle>
          <TextInput
            type="text"
            autoCapitalize="on"
            placeholder="For example Nikon Kit Lens"
            value={name}
            onChange={onNameChange}
          />
        </Label>
        <Label>
          <LabelTitle>
            <img src="/images/address.svg" alt="Location" />
            Location <LabelDetail>For personal items, select the office nearest to you</LabelDetail>
          </LabelTitle>
          <ReactSelect
            isMulti={false}
            onChange={onLocationChange}
            options={locationOptions.options}
            value={locationOptions.selected}
          />
        </Label>
        <Label>
          <LabelTitle>
            <img src="/images/address.svg" alt="Location details" />
            Location details
          </LabelTitle>
          <TextInput
            type="text"
            autoCapitalize="on"
            autoCorrect="on"
            placeholder="7th floor, over by the secret west corridor"
            value={locationInformation}
            onChange={onLocationInformationChange}
          />
        </Label>
        <Label>
          <LabelTitle>
            <img src="/images/qr-code.svg" alt="" />
            Reaktor QR Code Tag
            <LabelDetail>
              Don't add QR Code tag to keys. See also{' '}
              <a href="/instructions" target="_blank" rel="noreferrer">
                instructions
              </a>
              .
            </LabelDetail>
          </LabelTitle>
          <TextInput
            type="text"
            autoCapitalize="off"
            autoCorrect="off"
            placeholder="Reaktor QR Code ID only e.g. G0123"
            value={tag || ''}
            onChange={onTagChange}
          />
        </Label>
        <Label>
          <LabelTitle>
            <img src="/images/categories.svg" alt="" />
            Categories
          </LabelTitle>
          <CreatableSelect
            isMulti={true}
            onChange={onCategoriesChange}
            options={categoryOptions.options}
            value={categoryOptions.selected}
          />
        </Label>
        <Label>
          <LabelTitle>
            <img src="/images/nick.svg" alt="Contact persons" />
            Contacts / Owner
          </LabelTitle>
          <ReactSelect
            isMulti={true}
            onChange={onContactsChange}
            options={contactsOptions.options}
            value={contactsOptions.selected}
          />
        </Label>
        <Label>
          <LabelTitle>
            <img src="/images/slack.svg" alt="Slack" />
            Slack channel
          </LabelTitle>
          <TextInput
            type="text"
            autoCapitalize="off"
            autoComplete="off"
            placeholder="Channel name without #"
            value={slack}
            onChange={onSlackChange}
          />
        </Label>
        <Label>
          <LabelTitle>
            <img src="/images/borrowable.svg" alt="Slack" />
            Borrowable
          </LabelTitle>
          <div>
            <input type="checkbox" checked={loanable} onChange={onLoanableChange} />
            <LoanableText onClick={onLoanableTextClick}>Allow people to borrow this piece of gear</LoanableText>
          </div>
        </Label>
        <Label>
          <div>
            Description{' '}
            <LabelDetail>
              <a href="https://github.github.com/gfm/">GitHub Flavored Markdown is supported</a>
            </LabelDetail>
          </div>
          <TextArea
            autoCapitalize="on"
            rows={8}
            placeholder="Instructions and more infrormation for the item"
            value={description}
            onChange={onDescriptionChange}
          />
        </Label>
      </Details>
      {props.error && <div>{props.error}</div>}

      <Buttons>
        {props.item && (
          <DeleteButton active={props.saving} onClick={onDelete}>
            Delete
          </DeleteButton>
        )}

        {!props.item && (
          <Button active={props.saving} onClick={onNew}>
            {props.saving ? 'Saving' : 'Save'}
          </Button>
        )}
        {props.item && (
          <Button active={props.saving} onClick={onSave}>
            {props.saving ? 'Saving' : 'Save'}
          </Button>
        )}
      </Buttons>
    </Container>
  )
}
