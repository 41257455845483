import React from 'react'
import styled from 'styled-components'
import { Person } from '../../model'

export type Action = 'return' | 'borrow'

interface Props {
  persons: Person[]
}

const PersonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${props => props.theme.spacing(1)};
`

const ListContainer = styled.div`
  display: flex;
`

const PersonName = styled.a`
  color: ${props => props.theme.textColor};
  text-decoration: none;
`

export const PersonList: React.FC<Props> = (props: Props) => {
  return (
    <ListContainer>
      {props.persons.map(person => {
        return (
          <PersonContainer key={person.uid}>
            <PersonName href={`https://rex.reaktor.com/people/${person.uid}`}>{person.name}</PersonName>
          </PersonContainer>
        )
      })}
    </ListContainer>
  )
}
