import React from 'react'
import { Route } from 'react-router-dom'
import { useTriggerLogin } from '../../loginState'
import { Section } from '../../components/Section/Section'
import { preventingDefault } from '../../components/preventingDefault'
import { MenuContainer } from '../../components/MenuContainer/MenuContainer'
import { ContentContainer } from '../../components/ContentContainer/ContentContainer'
import { LoggedOutReportForm } from './LoggedOutReportForm'

export const LoggedOutPage: React.FC = () => {
  const triggerLogin = useTriggerLogin()
  const handleLoginButtonClick = preventingDefault(triggerLogin)

  return (
    <>
      <MenuContainer logoHref="/" />
      <ContentContainer>
        <Route path="/:tagCode([A-Z][0-9]{3,6})" exact={true}>
          <LoggedOutReportForm />
        </Route>
        <Section title="Reaktorian?" expandable={false} expanded={false}>
          <button onClick={handleLoginButtonClick}>Log in</button>
        </Section>
      </ContentContainer>
    </>
  )
}
