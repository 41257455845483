import React from 'react'
import styled from 'styled-components'
import { Category, GearItem } from '../../model'
import { Item } from '../../components/Item/Item'
import { CategoryList } from '../ListingPage/CategoryList'

const ListingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
`

const ListItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 100%;
`

const AddGear = styled.div`
  margin-top: ${props => props.theme.spacing(2)};
`

const Instruction = styled.div`
  div {
    margin-bottom: ${props => props.theme.spacing(2)};
  }
`

export interface ListingProps {
  items: GearItem[]
  loading: boolean
  categories: Category[]
  activeCategories: Category[]
  onActiveCategoriesChange: (categories: Category[]) => void
}

export const MyListing: React.FC<ListingProps> = (props: ListingProps) => {
  const noItems = props.items.length === 0

  return (
    <ListingContainer>
      <CategoryList
        activeCategories={props.activeCategories}
        categories={props.categories}
        onActiveCategoriesChange={props.onActiveCategoriesChange}
      />
      <ListItems>
        {props.items.map(item => (
          <Item key={item.id} item={item} />
        ))}
      </ListItems>
      {props.loading && <div>Fetching your gear ...</div>}
      {noItems && !props.loading && (
        <Instruction>
          <div>No gear yet - go ahead and add your umbrella, it's more likely to get back to you if you loose it.</div>
          <div>Here you'll see items on which you are the contact person / owner.</div>
        </Instruction>
      )}

      <AddGear>
        <a href="/add">Add Gear</a>
      </AddGear>
    </ListingContainer>
  )
}
