import React from 'react'
import { Category } from '../../model'
import styled from 'styled-components'

const Button = styled.div`
  height: 30px;
  line-height: 30px;
  margin-right: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  cursor: pointer;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid ${props => props.theme.buttonBorderColor};
  font-size: 14px;
  background-position: center left 4px;
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-color: ${props => props.theme.categoryButtonBackgroundColor};
  color: ${props => props.theme.categoryButtonTextColor};
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  border-radius: 30px;
`

const ActiveButton = styled(Button)`
  background: ${props => props.theme.categoryButtonActiveBackgroundColor};
  color: ${props => props.theme.categoryButtonActiveTextColor};
`

interface Props {
  category: Category
  active: boolean
  onClick: (category: Category, active: boolean) => void
}

export const CategoryButton: React.FC<Props> = (props: Props) => {
  const text = `${props.category.name} (${props.category.count})`

  const onClick = () => {
    props.onClick(props.category, !props.active)
  }

  if (props.active) {
    return <ActiveButton onClick={onClick}>{text}</ActiveButton>
  } else {
    return <Button onClick={onClick}>{text}</Button>
  }
}
