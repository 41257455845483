import styled from 'styled-components'

export const Value = styled.div`
  height: 1em;
`

export const NoValue = styled.div`
  font-style: italic;
  color: #555;
  height: 1em;

  a {
    color: #555;
  }
`

export const LabelValuePair = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing(2)};

  img {
    width: 20px;
    margin-right: ${props => props.theme.spacing(1)};
  }
`
