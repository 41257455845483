import styled from 'styled-components'

export const TextInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-top: 4px;
  font-size: 1em;
  padding: 0px 4px;
  line-height: 35px;
`
