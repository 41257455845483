import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDebounce } from '../../lib/UseDebounce'

const InputWrapper = styled.div`
  flex-grow: 1;
  max-width: 600px;
  margin: 12px 20px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #cad1d6;
  height: 40px;
  border-radius: 20px;
  font-size: 14px;
  color: #151515;
  background: #fff;
`

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  background: none;
  border: 0;
  font: inherit;
  font-size: 16px;
  padding: 11px 14px;
  box-sizing: border-box;
  padding-right: 36px;
`

const SearchIcon = styled.div`
  float: right;
  background-image: url(/images/search.svg);
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: center center;
  margin-top: 8px;
  margin-right: 12px;
  width: 20px;
  height: 20px;
`

interface Props {
  searchTerm: string
  onSearch: (searchTerms: string) => void
}

export const Search: React.FC<Props> = (props: Props) => {
  // Throttle would be better
  const [debouncedSearchTerm, searchTerm, setSearchTerm] = useDebounce<string>('', 300)

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  useEffect(() => {
    props.onSearch(debouncedSearchTerm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (props.searchTerm.length === 0) {
      setSearchTerm('')
    }
  }, [props.searchTerm, setSearchTerm])

  return (
    <InputWrapper>
      <Input type="text" placeholder="Search" value={searchTerm} onChange={onSearchChange} />
      <SearchIcon />
    </InputWrapper>
  )
}
