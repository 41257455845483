import React from 'react'
import styled from 'styled-components'
import { Category, SearchResults } from '../../model'
import { CategoryList } from './CategoryList'
import { Item } from '../../components/Item/Item'

const ListingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  margin-top: 30px;
`

const ListItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 100%;
`

export interface ListingProps {
  searchResults: SearchResults
  loading: boolean
  onActiveCategoriesChange: (activeCategories: Category[]) => void
}

export const ListingPage: React.FC<ListingProps> = (props: ListingProps) => {
  const activeCategories = props.searchResults.categories.filter(c =>
    props.searchResults.request.categories.includes(c.id),
  )

  return (
    <ListingContainer>
      <CategoryList
        activeCategories={activeCategories}
        categories={props.searchResults.categories}
        onActiveCategoriesChange={props.onActiveCategoriesChange}
      />
      <ListItems>
        {props.searchResults.items.map(item => (
          <Item key={item.id} item={item} />
        ))}
      </ListItems>

      {props.loading && <div>Loading items ...</div>}
      {!props.loading && props.searchResults.items.length === 0 && <div>No items.</div>}
    </ListingContainer>
  )
}
