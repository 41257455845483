import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Category } from '../../model'
import { CategoryButton } from '../../components/CategoryButton/CategoryButton'

const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 100%;
  margin-top: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(2)};
`

const ShowMore = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: ${props => props.theme.spacing(1)};
  padding-right: ${props => props.theme.spacing(1)};
`

const shownItemsLimit = 10

interface Props {
  categories: Category[]
  activeCategories: Category[]
  onActiveCategoriesChange: (categories: Category[]) => void
}

export const CategoryList: React.FC<Props> = (props: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [shownItems, setShownItems] = useState<Category[]>([])

  useEffect(() => {
    if (expanded) {
      setShownItems(props.categories)
    } else {
      const shortenedList = props.categories.slice(0, shownItemsLimit)
      const activeCategoriesNotInShortenedList = props.activeCategories.filter(
        ac => shortenedList.find(c => c.id === ac.id) === undefined,
      )
      setShownItems(shortenedList.concat(activeCategoriesNotInShortenedList))
    }
  }, [props.categories, props.activeCategories, expanded])

  const onCategoryButtonClick = (category: Category, active: boolean) => {
    if (active) {
      props.onActiveCategoriesChange([category])
    } else {
      props.onActiveCategoriesChange([])
    }
  }

  const onShowMore = () => {
    setExpanded(true)
  }

  const onShowLess = () => {
    setExpanded(false)
  }

  return (
    <Categories>
      {shownItems.map(category => {
        const active = props.activeCategories.find(ac => ac.id === category.id) !== undefined
        return <CategoryButton key={category.id} onClick={onCategoryButtonClick} category={category} active={active} />
      })}
      {!expanded && shownItems.length > 0 && props.categories.length > shownItemsLimit && (
        <ShowMore onClick={onShowMore}>Show all</ShowMore>
      )}
      {expanded && <ShowMore onClick={onShowLess}>Show less</ShowMore>}
    </Categories>
  )
}
