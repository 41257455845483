import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  font-family: 'Scto Grotesk A', 'Futura', 'Lucida Grande', sans-serif;

  input {
    font-family: 'Scto Grotesk A', 'Futura', 'Lucida Grande', sans-serif;
  }

  margin-bottom: 50px;
  display: flex;
  justify-content: space-around;
`

const Padding = styled.div`
  padding-top: calc(60px + 20px);
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
`

export const ContentContainer: React.FC<any> = (props: any) => {
  return (
    <Container>
      <Padding>{props.children}</Padding>
    </Container>
  )
}
