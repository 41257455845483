import React from 'react'
import styled from 'styled-components'

const Image = styled.img`
  max-width: 100%;
`

export const InstructionsPage: React.FC<{}> = () => {
  return (
    <div>
      <h1>Instructions</h1>
      <h2>Gear tags</h2>

      <p>
        You can use Reaktor branded physical sticker tags to identify Reaktor Gear. In Helsinki, tags can be found from
        the 3rd floor lockers.
      </p>

      <Image src="/images/instructions-fin-gear-tag-location.jpeg" alt="" />

      <p>Please don't tag:</p>

      <ul>
        <li>stuff with an admin ID already on it (i.e. laptops)</li>
        <li>
          stuff with a Hakonen barcode on it (i.e. furniture) as those have, at least for now, their own tracking
          systems and we don’t want to confuse their users.
        </li>
        <li>Please do not remove those existing ID stickers either.</li>
      </ul>

      <p>Stick the tag on to your gear:</p>

      <Image src="/images/instructions-example-gear-tag.jpeg" alt="" />

      <p>
        History and more information of gear tags can be found in{' '}
        <a href="https://discourse.reaktor.com/t/tag-your-reaktor-gear-so-that-it-or-its-owner-can-be-found-gear-reaktor-com-mvp-launch/4138">
          a Discourse post
        </a>
        .
      </p>
    </div>
  )
}
