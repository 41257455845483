import { useContext, createContext } from 'react'

type LoginStateContextType = Readonly<{
  notifyLoggedOut: () => void
  triggerLogin: () => void
}>

export const LoginStateContext = createContext<LoginStateContextType>({
  notifyLoggedOut: () => {
    /* no-op */
  },
  triggerLogin: () => {
    /* no-op */
  },
})

export const useNotifyLoggedOut = () => useContext(LoginStateContext).notifyLoggedOut
export const useTriggerLogin = () => useContext(LoginStateContext).triggerLogin
