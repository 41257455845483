import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useHistory } from 'react-router-dom'
import gfm from 'remark-gfm'
import styled from 'styled-components'
import { cloudinaryFullImage } from '../../lib/cloudinary'
import { Category, GearItem, Loan, Location, User } from '../../model'
import { ActivityHistory } from './ActivityHistory'
import { Button } from '../../components/Button/Button'
import { CategoryButton } from '../../components/CategoryButton/CategoryButton'
import { imageHeight, ImageView, imageWidth } from '../../components/ImageView/ImageView'
import { Section } from '../../components/Section/Section'
import { BorrowButton } from './BorrowButton'
import { LabelValuePair, NoValue, Value } from './Labels'
import { PersonList } from './PersonList'

const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  max-width: ${props => props.theme.itemPageMaxWidth};
  margin-left: auto;
  margin-right: auto;
`

const Title = styled.h1`
  margin-bottom: 16px;
`

const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
`

interface FormProps {
  categories: Category[]
  item: GearItem
  locations: Location[]
  user: User
  loans: Loan[]
  onCategoryNavigation: (category: Category) => void
  onLoan: () => void
  onReturn: (loanId: number) => void
}

export const ItemViewForm: React.FC<FormProps> = (props: FormProps) => {
  const history = useHistory()
  const [categories, setCategories] = useState<Category[]>([])

  useEffect(() => {
    const itemCategories = (props.item.categories
      .map(category => {
        return props.categories.find(c => c.id === category)
      })
      .filter(c => c !== undefined) as Category[]).sort((left: Category, right: Category) =>
      left.name.localeCompare(right.name),
    )
    setCategories(itemCategories)
  }, [props.item.categories, props.categories])

  const onEdit = () => {
    history.push(`/items/${props.item.id}/edit`)
  }

  const generateImageUrl = () => {
    const photos = props.item.photos
    if (photos.length > 0) {
      return { original: photos[0].url, cropped: cloudinaryFullImage(photos[0].url, imageWidth, imageHeight) }
    }
    return { original: undefined, cropped: undefined }
  }

  const imageUrl = generateImageUrl()

  return (
    <Container>
      <ImageView image={imageUrl} />
      <Title>{props.item.name}</Title>
      <Categories>
        {categories.map(category => {
          return (
            <CategoryButton key={category.id} onClick={props.onCategoryNavigation} category={category} active={false} />
          )
        })}
      </Categories>

      <LabelValuePair>
        <img src="/images/address.svg" alt="Location" />
        <Value>
          {props.locations.find(location => location.id === props.item.location)?.name || ''}
          {props.item.location_information.length > 0 && <>&nbsp;-&nbsp;</>}
          {props.item.location_information}
        </Value>
      </LabelValuePair>

      <LabelValuePair>
        <img src="/images/qr-code.svg" alt="QR Code" />
        <Value>
          {props.item.tag}
          {!props.item.tag && (
            <NoValue>
              No tag - <a href="/instructions">instructions on how to add a tag</a>
            </NoValue>
          )}
        </Value>
      </LabelValuePair>

      <LabelValuePair>
        <img src="/images/key.svg" alt="Owned by" />
        <Value>
          {props.item.personal && <span>Is personal gear</span>}
          {!props.item.personal && <span>Owned by Reaktor</span>}
        </Value>
      </LabelValuePair>

      <LabelValuePair>
        <img src="/images/nick.svg" alt="Contact persons" />
        <Value>
          {props.item.contacts.length > 0 && <PersonList persons={props.item.contacts} />}
          {props.item.contacts.length === 0 && <NoValue>No contact persons</NoValue>}
        </Value>
      </LabelValuePair>

      <LabelValuePair>
        <img src="/images/slack.svg" alt="" />
        <Value>
          {props.item.slack}
          {props.item.slack.length === 0 && <NoValue>No slack channel</NoValue>}
        </Value>
      </LabelValuePair>

      <BorrowButton
        user={props.user}
        loans={props.loans}
        onReturn={props.onReturn}
        onLoan={props.onLoan}
        item={props.item}
      />

      <Section expandable={false} expanded={false} title="Description">
        <LabelValuePair>
          <ReactMarkdown plugins={[gfm]} children={props.item.description} />
        </LabelValuePair>
        {props.item.description.length === 0 && <NoValue>No description</NoValue>}
      </Section>

      <ActivityHistory loans={props.loans} item={props.item} />

      <Button active={false} onClick={onEdit}>
        Edit
      </Button>
    </Container>
  )
}
