const cloudinaryUrlRegex = /(http(?:s|):\/\/res.cloudinary.com\/(?:[a-zA-Z0-9]+)\/image\/upload\/)(\S+\/|)(v[0-9]+\/.*)/

function getCloudinaryUrlWithTransformation(url: string | null, transformation: string) {
  if (typeof url !== 'string') return undefined

  const match = cloudinaryUrlRegex.exec(url)

  return match ? [match[1], `${transformation}/`, match[3]].join('') : url
}

export const cloudinaryFitImage = (imageUrl: string, imageWidth: number, imageHeight: number) => {
  return getCloudinaryUrlWithTransformation(imageUrl, `w_${imageWidth},h_${imageHeight},c_fill,q_60`)
}

export const cloudinaryFullImage = (imageUrl: string, imageWidth: number, imageHeight: number) => {
  return getCloudinaryUrlWithTransformation(imageUrl, `w_${imageWidth},h_${imageHeight},c_limit,b_white,q_auto`)
}
