import React from 'react'
import { useParams } from 'react-router-dom'
import { Category, GearItem, Loan, Location, User } from '../../model'
import { Endpoints, post, useGet } from '../../Network'
import { ItemViewForm } from './ItemViewForm'
import { useNotifyLoggedOut } from '../../loginState'

interface ItemPageProps {
  categories: Category[]
  locations: Location[]
  user: User
  onCategoryNavigation: (category: Category) => void
}

interface ItemPageParams {
  itemId: string
}

export const ItemViewPage: React.FC<ItemPageProps> = (props: ItemPageProps) => {
  const { itemId } = useParams<ItemPageParams>()
  const notifyLoggedOut = useNotifyLoggedOut()
  const loans = useGet<Loan[]>(Endpoints.getLoans(Number(itemId)), [])
  const itemGet = useGet<GearItem | null>(Endpoints.getItem(itemId), null)

  const onLoan = async () => {
    await post(Endpoints.postLoan(Number(itemId)), {}, notifyLoggedOut)
    loans.revalidate()
  }

  const onReturn = async (loanId: number) => {
    await post(Endpoints.postReturnLoan(loanId), {}, notifyLoggedOut)
    loans.revalidate()
  }

  const item = itemGet.data

  if (item && props.categories) {
    return (
      <ItemViewForm
        categories={props.categories}
        item={item}
        locations={props.locations}
        user={props.user}
        onCategoryNavigation={props.onCategoryNavigation}
        loans={loans.data}
        onLoan={onLoan}
        onReturn={onReturn}
      />
    )
  } else {
    return <div />
  }
}
