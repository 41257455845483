// This file is copy-pasted between frontend and backend

import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString'

export const CountrySelectionCodec = t.union([
  t.literal('globe'),
  t.literal('FI'),
  t.literal('JP'),
  t.literal('NL'),
  t.literal('SE'),
  t.literal('AE'),
  t.literal('US'),
])

export type CountrySelection = t.TypeOf<typeof CountrySelectionCodec>

export const PhotoCodec = t.strict({
  url: t.string,
})

export type Photo = t.TypeOf<typeof PhotoCodec>

export const PersonCodec = t.strict({
  name: t.string,
  uid: t.string,
  image: t.string,
})

export const LocationCodec = t.strict({
  id: t.number,
  name: t.string,
  country: CountrySelectionCodec,
})

export type Location = t.TypeOf<typeof LocationCodec>

export type Person = t.TypeOf<typeof PersonCodec>

export const GearItemPartialType = t.strict({
  categories: t.array(t.number),
  contacts: t.array(t.string),
  description: t.string,
  loanable: t.boolean,
  location: t.number,
  location_information: t.string,
  name: t.string,
  personal: t.boolean,
  photos: t.array(PhotoCodec),
  slack: t.string,
  tag: t.union([t.string, t.null]),
})

export type GearItemPartial = t.TypeOf<typeof GearItemPartialType>

export const GearItemCodec = t.strict({
  id: t.number,
  updated_at: DateFromISOString,
  created_at: DateFromISOString,
  created_by: PersonCodec,
  categories: t.array(t.number),
  contacts: t.array(PersonCodec),
  description: t.string,
  loanable: t.boolean,
  location: t.number,
  location_information: t.string,
  name: t.string,
  personal: t.boolean,
  photos: t.array(PhotoCodec),
  slack: t.string,
  tag: t.union([t.string, t.null]),
})

export type GearItem = t.TypeOf<typeof GearItemCodec>

export const CategoryCodec = t.strict({
  id: t.number,
  name: t.string,
  created_at: DateFromISOString,
  count: t.number,
})

export type Category = t.TypeOf<typeof CategoryCodec>

export const CategoryPartialCodec = t.strict({
  name: t.string,
})

export type CategoryPartial = t.TypeOf<typeof CategoryPartialCodec>

export const UserCodec = t.strict({
  name: t.string,
  uid: t.string,
  defaultLocation: LocationCodec,
})

export type User = t.TypeOf<typeof UserCodec>

export const SearchRequestCodec = t.strict({
  terms: t.string,
  categories: t.array(t.number),
  country: CountrySelectionCodec,
  loanable: t.union([t.boolean, t.null]),
  contact: t.union([t.string, t.null]),
  personal: t.union([t.boolean, t.null]),
})

export type SearchRequest = t.TypeOf<typeof SearchRequestCodec>

export const SearchResultsCodec = t.strict({
  items: t.array(GearItemCodec),
  categories: t.array(CategoryCodec),
  request: SearchRequestCodec,
})

export type SearchResults = t.TypeOf<typeof SearchResultsCodec>

export const LoanCodec = t.strict({
  id: t.number,
  gear_id: t.number,
  start: DateFromISOString,
  end: t.union([DateFromISOString, t.null]),
  updated_by: t.union([PersonCodec, t.null]),
  updated_at: t.union([DateFromISOString, t.null]),
  created_by: PersonCodec,
  created_at: DateFromISOString,
})

export type Loan = t.TypeOf<typeof LoanCodec>

export const LoanAndItemCodec = t.strict({
  loan: LoanCodec,
  item: GearItemCodec,
})

export type LoanAndItem = t.TypeOf<typeof LoanAndItemCodec>
