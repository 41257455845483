import React from 'react'
import { Submenu } from '../../components/Submenu/Submenu'
import { Tab } from '../../components/Submenu/Tabs'
import { Category, SearchResults } from '../../model'
import { MyGear } from './MyGear'

export interface MyGearPageProps {
  searchResults: SearchResults
  loading: boolean
  onActiveCategoriesChange: (activeCategories: Category[]) => void
  onTabChange: (tab: Tab) => void
}

export const MyGearPage: React.FC<MyGearPageProps> = (props: MyGearPageProps) => {
  return (
    <>
      <Submenu activeTab={'mygear'} onTabChange={props.onTabChange} />
      <MyGear
        searchResults={props.searchResults}
        loading={props.loading}
        onActiveCategoriesChange={props.onActiveCategoriesChange}
      />
    </>
  )
}
