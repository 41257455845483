import React from 'react'
import styled from 'styled-components'

interface Props {
  personal: boolean
  onSelect?: (isPersonal: boolean) => void
}

const Container = styled.div<{ enabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.enabled ? 'pointer' : 'default')};
`

const Selection = styled.div<{ active: boolean }>`
  display: inline;
  height: ${props => props.theme.buttonHeightThin};
  line-height: ${props => props.theme.buttonHeightThin};
  flex-grow: 1;
  border: 1px solid ${props => props.theme.main};
  text-align: center;
  border-color: ${props => props.theme.toggleBorderColor};
  background-color: ${props =>
    props.active ? props.theme.toggleSelectedBackgroundColor : props.theme.toggleNotSelectedBackgroundColor};
  color: ${props => (props.active ? props.theme.toggleSelectedTextColor : props.theme.toggleNotSelectedTextColor)};
  margin-top: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(2)};
  max-width: 200px;
`
const Left = styled(Selection)`
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`

const Right = styled(Selection)`
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: none;
`

export const GearOwnerSelector: React.FC<Props> = (props: Props) => {
  const onReaktorClick = () => {
    if (props.onSelect) {
      props.onSelect(false)
    }
  }

  const onPersonalClick = () => {
    if (props.onSelect) {
      props.onSelect(true)
    }
  }

  const enabled = props.onSelect !== undefined

  return (
    <Container enabled={enabled}>
      <Left active={!props.personal} onClick={onReaktorClick}>
        Reaktor Gear
      </Left>
      <Right active={props.personal} onClick={onPersonalClick}>
        Personal Gear
      </Right>
    </Container>
  )
}
