export interface Theme {
  backgroundColor: string
  buttonBorderColor: string
  buttonHeight: string
  buttonHeightThin: string
  buttonDeleteColor: string
  buttonNormalColor: string
  buttonNormalTextColor: string
  buttonNormalColorHover: string
  buttonOkColor: string
  buttonOkColorHover: string
  buttonOkTextColor: string
  categoryButtonBackgroundColor: string
  categoryButtonTextColor: string
  categoryButtonActiveBackgroundColor: string
  categoryButtonActiveTextColor: string
  breakpoint: string
  dividerColor: string
  imageBorderRadius: string
  imageBlankBackground: string
  imageBlankTextColor: string
  inputBorderColor: string
  itemPageMaxWidth: string
  logoColor: string
  submenuButtonTextColor: string
  submenuActiveButtonColor: string
  menuBackgroundColor: string
  submenuBackgroundColor: string
  textColor: string
  textColorSecondary: string
  toggleNotSelectedBackgroundColor: string
  toggleNotSelectedTextColor: string
  toggleSelectedBackgroundColor: string
  toggleSelectedTextColor: string
  toggleBorderColor: string
  spacing: (multiplier: number) => string
}

const veryDarkBlue = '#00263A'
const darkBlue = '#00263A'
const white = '#FFFFFF'
const gray = '#566570'
const veryLightGray = '#E0E7ED'
const red = '#F44336'

const darkGreen = '#357a38'
const green = '#4caf50'

const lightTheme: Theme = {
  backgroundColor: '#ffffff',
  buttonBorderColor: veryLightGray,
  buttonHeight: '44px',
  buttonHeightThin: '30px',
  buttonDeleteColor: red,
  buttonNormalColor: veryDarkBlue,
  buttonNormalTextColor: white,
  buttonNormalColorHover: veryDarkBlue,
  buttonOkColor: green,
  buttonOkColorHover: darkGreen,
  buttonOkTextColor: white,
  categoryButtonBackgroundColor: white,
  categoryButtonTextColor: 'black',
  categoryButtonActiveBackgroundColor: darkBlue,
  categoryButtonActiveTextColor: white,
  breakpoint: '768px',
  dividerColor: '#cad1d6',
  imageBorderRadius: '4px',
  imageBlankBackground: veryLightGray,
  imageBlankTextColor: gray,
  inputBorderColor: veryLightGray,
  itemPageMaxWidth: '1024px',
  logoColor: 'black',
  menuBackgroundColor: '#f5f8fc',
  submenuActiveButtonColor: '#f5f8fc',
  submenuButtonTextColor: 'black',
  submenuBackgroundColor: '#e0e7ed',
  textColor: veryDarkBlue,
  textColorSecondary: gray,
  toggleNotSelectedBackgroundColor: white,
  toggleNotSelectedTextColor: veryDarkBlue,
  toggleSelectedBackgroundColor: darkBlue,
  toggleSelectedTextColor: white,
  toggleBorderColor: veryDarkBlue,
  spacing: (multiplier: number) => `${multiplier * 8}px`,
}

/*
const gray1 = "#111";
const gray2 = "#222";
const gray3 = "#333";
const gray4 = "#444";
const gray5 = "#555";
const gray6 = "#666";
const gray7 = "#777";
const gray8 = "#888";
const gray9 = "#999";
const grayA = "#aaa";
const grayB = "#bbb";
const grayC = "#ccc";
const grayD = "#ddd";
const grayE = "#eee";


const darkTheme: Theme = {
  ...lightTheme,
  backgroundColor: "#303030",
  buttonBorderColor: gray2,
  buttonDeleteColor: red,
  buttonNormalColor: veryDarkBlue,
  buttonNormalTextColor: white,
  buttonNormalColorHover: veryDarkBlue,
  buttonOkColor: green,
  buttonOkColorHover: darkGreen,
  buttonOkTextColor: white,
  categoryButtonBackgroundColor: gray2,
  categoryButtonTextColor: gray9,
  categoryButtonActiveBackgroundColor: gray5,
  categoryButtonActiveTextColor: white,
  dividerColor: gray6,
  imageBlankBackground: gray2,
  imageBlankTextColor: gray,
  inputBorderColor: veryLightGray,
  logoColor: white,
  menuBackgroundColor: gray5,
  submenuActiveButtonColor: gray5,
  submenuButtonTextColor: white,
  submenuBackgroundColor: gray2,
  textColor: white,
  textColorSecondary: gray,
  toggleNotSelectedBackgroundColor: gray2,
  toggleNotSelectedTextColor: gray9,
  toggleSelectedBackgroundColor: gray5,
  toggleSelectedTextColor: white,
  toggleBorderColor: gray3,
}
*/

export const theme = lightTheme
