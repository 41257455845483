import React from 'react'
import { Category, SearchResults } from '../model'
import { ListingPage } from './ListingPage/ListingPage'

export interface SearchPageProps {
  searchResults: SearchResults
  loading: boolean
  onActiveCategoriesChange: (activeCategories: Category[]) => void
}

export const SearchPage: React.FC<SearchPageProps> = (props: SearchPageProps) => {
  return (
    <ListingPage
      searchResults={props.searchResults}
      loading={props.loading}
      onActiveCategoriesChange={props.onActiveCategoriesChange}
    />
  )
}
