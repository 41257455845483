import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as PlusLarge } from '../../assets/plus-large.svg'
import { CountrySelection } from '../../model'
import { CountrySelector } from './CountrySelector'
import { Search } from './Search'
import { MenuContainer } from '../MenuContainer/MenuContainer'

const AddNewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  margin-right: ${props => props.theme.spacing(2)};
  width: 40px;
  height: 40px;
  border: 1px solid ${props => props.theme.inputBorderColor};
  border-radius: 20px;
`

const AddNew = styled(PlusLarge)`
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  path {
    stroke: ${props => props.theme.submenuButtonTextColor};
  }
`

interface Props {
  searchTerm: string
  onSearch: (terms: string) => void
  logoHref: string
  onLogoClick: () => void
  activeCountry: CountrySelection
  onActiveCountryChange: (country: CountrySelection) => void
}

export const Menu: React.FC<Props> = (props: Props) => {
  const history = useHistory()

  const onAddNew = () => {
    history.push('/add')
  }

  return (
    <MenuContainer logoHref={props.logoHref} onLogoClick={props.onLogoClick}>
      <Search onSearch={props.onSearch} searchTerm={props.searchTerm} />
      <CountrySelector activeCountry={props.activeCountry} onActiveCountryChange={props.onActiveCountryChange} />
      <AddNewWrapper onClick={onAddNew}>
        <AddNew />
      </AddNewWrapper>
    </MenuContainer>
  )
}
