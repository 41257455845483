import React from 'react'
import styled from 'styled-components'
import { LoanAndItem } from '../../model'
import { Endpoints, useGet } from '../../Network'
import { Item } from '../../components/Item/Item'
import { Section } from '../../components/Section/Section'

const Container = styled.div``

const ListItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 100%;
`
export interface Props {}

export const LoansByUser: React.FC<Props> = (props: Props) => {
  const loansByUser = useGet<LoanAndItem[]>(Endpoints.getLoansByUser(), [])

  return (
    <Container>
      <Section title="Items borrowed by you" expandable={false} expanded={false}>
        <ListItems>
          {loansByUser.data.map(itemAndLoan => (
            <Item key={itemAndLoan.loan.id} item={itemAndLoan.item} />
          ))}
        </ListItems>
      </Section>
    </Container>
  )
}
