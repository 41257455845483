import React, { useEffect, useState } from 'react'
import { GearItem, Loan, User } from '../../model'
import { ButtonNormal } from '../../components/Button/Button'
import { LabelValuePair, NoValue } from './Labels'

interface Props {
  loans: Loan[]
  user: User
  item: GearItem
  onLoan: () => void
  onReturn: (loanId: number) => void
}

export const BorrowButton: React.FC<Props> = (props: Props) => {
  const [currentLoan, setCurrentLoan] = useState<Loan | null>()

  useEffect(() => {
    if (props.loans.length > 0 && props.loans[0].end == null) {
      setCurrentLoan(props.loans[0])
    } else {
      setCurrentLoan(null)
    }
  }, [props.loans, props.item.loanable])

  const onReturn = () => {
    if (currentLoan) {
      props.onReturn(currentLoan.id)
    }
  }

  const loanedByTheUser = currentLoan && props.user.uid === currentLoan.created_by.uid

  if (props.item.loanable || loanedByTheUser) {
    return (
      <div>
        {currentLoan && loanedByTheUser && (
          <ButtonNormal onClick={onReturn} active={false}>
            Return the item
          </ButtonNormal>
        )}
        {currentLoan && !loanedByTheUser && (
          <ButtonNormal onClick={onReturn} active={false}>
            Loaned by {currentLoan?.created_by.name}, return on their behalf
          </ButtonNormal>
        )}
        {!currentLoan && (
          <ButtonNormal onClick={props.onLoan} active={false}>
            Borrow the item
          </ButtonNormal>
        )}
      </div>
    )
  } else {
    return (
      <LabelValuePair>
        <img src="/images/borrowable.svg" alt="Owned by" />
        <NoValue>This gear is not borrowable</NoValue>
      </LabelValuePair>
    )
  }
}
