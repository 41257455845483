import React from 'react'
import styled from 'styled-components'
import { Tab } from './Tabs'

const MenuContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 44px;
  top: 60px;
  left: 0px;
  z-index: 4;
  display: flex;
  align-self: center;
  align-items: center;
  padding: 0;
  background: ${props => props.theme.submenuBackgroundColor};
  padding-left: ${props => props.theme.spacing(2)};
`

const Buttons = styled.div`
  display: flex;
  padding-top: ${props => props.theme.spacing(1)};
  align-items: stretch;
  height: calc(44px - ${props => props.theme.spacing(1)});
`

const Button = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: ${props => (props.active ? props.theme.submenuActiveButtonColor : 'transparent')};
  padding-left: ${props => props.theme.spacing(1)};
  padding-right: ${props => props.theme.spacing(1)};
  margin-right: ${props => props.theme.spacing(1)};
  color: ${props => props.theme.submenuButtonTextColor};
`

interface Props {
  activeTab: Tab
  onTabChange: (tab: Tab) => void
}

export const Submenu: React.FC<Props> = (props: Props) => {
  const onLoanable = () => {
    props.onTabChange('borrowable')
  }
  const onMyGear = () => {
    props.onTabChange('mygear')
  }
  const onReaktor = () => {
    props.onTabChange('reaktor')
  }
  const onAll = () => {
    props.onTabChange('all')
  }

  return (
    <MenuContainer>
      <Buttons>
        <Button onClick={onLoanable} active={props.activeTab === 'borrowable'}>
          Borrowable
        </Button>
        <Button onClick={onReaktor} active={props.activeTab === 'reaktor'}>
          Reaktor
        </Button>
        <Button onClick={onMyGear} active={props.activeTab === 'mygear'}>
          My Gear
        </Button>
        <Button onClick={onAll} active={props.activeTab === 'all'}>
          All
        </Button>
      </Buttons>
    </MenuContainer>
  )
}
