import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from '../../lib/UseQuery'
import { Category, CategoryPartial, GearItem, GearItemPartial, Location, Person, User } from '../../model'
import { del, Endpoints, post, postFile, put, useGet } from '../../Network'
import { ItemEditForm } from './ItemEditForm'
import { useNotifyLoggedOut } from '../../loginState'

interface ItemPageProps {
  categories: Category[]
  locations: Location[]
  update: () => void
  user: User
}

interface ItemPageParams {
  itemId: string
}

export const ItemEditPage: React.FC<ItemPageProps> = (props: ItemPageProps) => {
  const { itemId } = useParams<ItemPageParams>()
  const query = useQuery()
  const notifyLoggedOut = useNotifyLoggedOut()
  const itemGet = useGet<GearItem | null>(Endpoints.getItem(itemId), null)
  const peopleGet = useGet<Person[]>(Endpoints.getPeople(), [])
  const [saving, setSaving] = useState(false)
  const history = useHistory()
  const [error, setError] = useState<string | null>(null)

  const updatePhotos = async (id: number, photosToUpload: File[], photosToDelete: string[]) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const _photoToDelete of photosToDelete) {
      // TODO: YEAH... Ought to be photo id, not supported
      await del(Endpoints.deleteItemPhoto(id), notifyLoggedOut)
    }
    for (const photoToUpload of photosToUpload) {
      await postFile(Endpoints.postItemPhoto(id), photoToUpload)
    }
  }

  const createCategories = async (categoriesToCreate: string[]): Promise<number[]> => {
    let categories: Category[] = []
    for (const category of categoriesToCreate) {
      const c = await post<CategoryPartial, Category>(Endpoints.postCategory(), { name: category }, notifyLoggedOut)
      categories = categories.concat([c])
    }
    return categories.map(category => category.id)
  }

  const saveIt = async (
    operation: 'new' | 'save',
    item: GearItemPartial,
    photosToUpload: File[],
    photosToDelete: string[],
    categoriesToCreate: string[],
  ) => {
    setSaving(true)
    setError(null)
    try {
      const categories = await createCategories(categoriesToCreate)
      const itemWithNewCategories = { ...item, categories: item.categories.concat(categories) }

      let result
      if (operation === 'new') {
        result = await post<GearItemPartial, GearItem>(Endpoints.postItem(), itemWithNewCategories, notifyLoggedOut)
      } else {
        result = await put<GearItemPartial, GearItem>(Endpoints.putItem(itemId), itemWithNewCategories, notifyLoggedOut)
      }
      await updatePhotos(result.id, photosToUpload, photosToDelete)
      props.update()
      history.push(`/items/${result.id}`)
    } catch (error) {
      setError(`Could not save item: ${error}`)
    }
    setSaving(false)
  }

  const onNew = async (
    item: GearItemPartial,
    photosToUpload: File[],
    photosToDelete: string[],
    categoriesToCreate: string[],
  ) => {
    await saveIt('new', item, photosToUpload, photosToDelete, categoriesToCreate)
  }

  const onSave = async (
    item: GearItemPartial,
    photosToUpload: File[],
    photosToDelete: string[],
    categoriesToCreate: string[],
  ) => {
    await saveIt('save', item, photosToUpload, photosToDelete, categoriesToCreate)
  }

  const item = itemGet.data

  const onDelete = async () => {
    if (item?.id) {
      await del(Endpoints.deleteItem(item?.id), notifyLoggedOut)
      props.update()
      history.push('/')
    }
  }

  return (
    <ItemEditForm
      onNew={onNew}
      item={item || undefined}
      onSave={onSave}
      onDelete={onDelete}
      categories={props.categories}
      people={peopleGet.data}
      user={props.user}
      saving={saving}
      error={error}
      locations={props.locations}
      prefilledTag={query.get('tag')}
    />
  )
}
