import styled from 'styled-components'

export const Label = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: ${props => props.theme.spacing(3)};
  max-width: 100%;

  img {
    width: 20px;
    margin-right: ${props => props.theme.spacing(1)};
  }
`

export const LabelTitle = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${props => props.theme.spacing(0.5)};
`

export const LabelDetail = styled.span`
  font-size: 12px;
  padding-left: 8px;
`
