import React, { useState, useEffect } from 'react'
import { LoginStateContext } from './loginState'
import { login, checkIsLoggedIn } from './Network'

export type LoginStateHandlerProps = Readonly<{
  loggedInView: React.ReactNode
  loggedOutView: React.ReactNode
  checkingStatusView: React.ReactNode
}>

export const LoginStateHandler: React.FC<LoginStateHandlerProps> = ({
  loggedInView,
  loggedOutView,
  checkingStatusView,
}) => {
  const [loginState, setLoginState] = useState<'checking' | 'loggedIn' | 'notLoggedIn'>('notLoggedIn')
  const [error, setError] = useState<any>(undefined)

  useEffect(() => {
    checkIsLoggedIn().then(isLoggedIn => setLoginState(isLoggedIn ? 'loggedIn' : 'notLoggedIn'))
  }, [])

  useEffect(() => {
    if (error) {
      throw error
    }
  }, [error])

  const triggerLogin = async () => {
    const result = await login()

    switch (result.type) {
      case 'success': {
        setLoginState('loggedIn')
        break
      }
      case 'interactionRequired': {
        window.location.href = result.url.href
        break
      }
      case 'error': {
        setError(new Error(result.displayMessage))
        break
      }
    }
  }

  const notifyLoggedOut = () => {
    setLoginState('notLoggedIn')
  }

  return (
    <LoginStateContext.Provider value={{ notifyLoggedOut, triggerLogin }}>
      {loginState === 'loggedIn' ? loggedInView : null}
      {loginState === 'notLoggedIn' ? loggedOutView : null}
      {loginState === 'checking' ? checkingStatusView : null}
    </LoginStateContext.Provider>
  )
}
