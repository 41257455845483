import React from 'react'
import { Person } from '../../model'
import styled from 'styled-components'
import { DateTime } from 'luxon'

export interface ActivityItem {
  id: string
  date: Date
  person: Person
  text: string
}

interface Props {
  activityItem: ActivityItem
}

const Container = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.spacing(3)};
`

const Portrait = styled.img`
  width: 38px;
  height: 38px;
  object-fit: cover;
  object-position: center center;
  border-radius: 50%;
`

const Text = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-left: ${props => props.theme.spacing(2)};
`

const ActionText = styled.span`
  color: ${props => props.theme.textColor}
  font-size: 0.7em;
`

const PersonName = styled.a`
  color: ${props => props.theme.textColor};
  text-decoration: none;
  margin-bottom: ${props => props.theme.spacing(0.5)};
`

export const LoanHistoryItem: React.FC<Props> = (props: Props) => {
  return (
    <Container>
      <Portrait src={props.activityItem.person.image} />
      <Text>
        <PersonName href={`https://rex.reaktor.com/people/${props.activityItem.person.uid}`}>
          {props.activityItem.person.name}
        </PersonName>
        <ActionText>
          {props.activityItem.text} on{' '}
          {DateTime.fromJSDate(props.activityItem.date).toLocaleString(DateTime.DATETIME_MED)}
        </ActionText>
      </Text>
    </Container>
  )
}
