import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { CountrySelection } from '../../model'
import { CountryButton } from './CountryButton'

const CountryWrapper = styled.div`
  width: 48px;
  height: 44px;
  padding: 8px;
  cursor: pointer;

  :hover {
    background-color: #e0e7ed;
  }
`

const Country = styled.div`
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 40px;
  cursor: pointer;
  background-size: contain;
`

const CountryMenu = styled.div`
  position: relative;
  float: left;
  width: 60px;
  background-color: white;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
`

interface Props {
  activeCountry: CountrySelection
  onActiveCountryChange: (country: CountrySelection) => void
}

const countries: CountrySelection[] = ['globe', 'JP', 'FI', 'NL', 'SE', 'AE', 'US']
export const CountrySelector: React.FC<Props> = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  const onClick = () => {
    setOpen(!open)
  }

  const onActiveCountryChange = (country: CountrySelection) => {
    setOpen(false)
    props.onActiveCountryChange(country)
  }

  const onMouseDownEvent = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDownEvent)
    return () => {
      document.removeEventListener('mousedown', onMouseDownEvent)
    }
  }, [])

  return (
    <div style={{ height: '100%' }} ref={ref}>
      <CountryWrapper key={props.activeCountry} onClick={onClick}>
        <Country style={{ backgroundImage: `url(/images/locations/${props.activeCountry}.svg)` }} />
      </CountryWrapper>

      {open && (
        <CountryMenu>
          {countries.map(country => (
            <CountryButton key={country} country={country} onClick={onActiveCountryChange} />
          ))}
        </CountryMenu>
      )}
    </div>
  )
}
