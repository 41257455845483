import React, { ChangeEvent, DragEvent, MouseEvent } from 'react'
import styled from 'styled-components'

export const imageWidth = 640
export const imageHeight = 300

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: ${imageHeight}px;
`

const Image = styled.img`
  max-width: 100%;
  max-height: ${imageHeight}px;
  background: white;
  object-fit: contain;
  border-radius: ${props => props.theme.imageBorderRadius};
`

const NoImage = styled.div`
  display: flex;
  padding-left: ${props => props.theme.spacing(2)};
  padding-right: ${props => props.theme.spacing(2)};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${imageHeight}px;
  border-radius: ${props => props.theme.imageBorderRadius};
  background: ${props => props.theme.imageBlankBackground};
`

const InstructionText = styled.div`
  color: ${props => props.theme.imageBlankTextColor};
  margin-top: ${props => props.theme.spacing(4)};
  text-align: center;
`

const DeleteButton = styled.img`
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 8px;
  border-bottom-left-radius: 8px;
  float: right;
  position: absolute;
  top: 0;
  right: 0;
`

const ImageWrapper = styled.div`
  position: relative;
`

const Label = styled.label`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
`

interface Props {
  image: { original: string | undefined; cropped: string | undefined }
  onChange?: (file: File) => void
  onDelete?: () => void
}

export const ImageView: React.FC<Props> = (props: Props) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files ? event.target.files[0] : null
    if (uploadedFile && props.onChange) {
      props.onChange(uploadedFile)
    }
  }

  const onDropEvent = (event: DragEvent) => {
    if (props.onChange && event.dataTransfer.files.length === 1) {
      props.onChange(event.dataTransfer.files[0])
    }
    event.preventDefault()
    event.stopPropagation()
  }

  const onDragEnter = (event: DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }
  const onDragLeave = (event: DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const onDragOver = (event: DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const onDelete = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    if (props.onDelete) {
      props.onDelete()
    }
  }

  if (props.onChange) {
    return (
      <ImageContainer onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDropEvent} onDragOver={onDragOver}>
        <Label>
          {props.image.original && (
            <ImageWrapper>
              <Image src={props.image.cropped} />
              <DeleteButton onClick={onDelete} src="/images/cross-white.svg" />
            </ImageWrapper>
          )}

          {!props.image.original && (
            <NoImage>
              <img src="/images/camera.svg" alt="" />
              <InstructionText>Click or drag a photo over to change</InstructionText>
            </NoImage>
          )}

          <input type="file" accept="image/*" style={{ position: 'absolute', top: '-100em' }} onChange={onChange} />
        </Label>
      </ImageContainer>
    )
  } else {
    return (
      <ImageContainer>
        {props.image.original && (
          <a href={props.image.original}>
            <Image src={props.image.cropped} />
          </a>
        )}
        {!props.image.original && (
          <NoImage>
            <img src="/images/camera.svg" alt="" />
            <InstructionText>Snap a photo to add here, would you?</InstructionText>
          </NoImage>
        )}
      </ImageContainer>
    )
  }
}
