import React from 'react'
import styled from 'styled-components'
import { CountrySelection } from '../../model'

const CountryWrapper = styled.div`
  height: 40px;
  padding: 8px;
  cursor: pointer;

  :hover {
    background-color: #e0e7ed;
  }
`

const Country = styled.div`
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px 40px;
  cursor: pointer;
  background-size: contain;
`

interface Props {
  country: CountrySelection
  onClick: (country: CountrySelection) => void
}

export const CountryButton: React.FC<Props> = (props: Props) => {
  const onClick = () => {
    props.onClick(props.country)
  }

  return (
    <CountryWrapper key={props.country} onClick={onClick}>
      <Country style={{ backgroundImage: `url(/images/locations/${props.country}.svg)` }} />
    </CountryWrapper>
  )
}
