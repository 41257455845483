import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useGet, Endpoints } from './Network'
import { GearItem } from './model'

export const ScanLinkHandler: React.FC = () => {
  const { tagCode } = useParams<{ tagCode: string }>()
  const { data, error } = useGet<GearItem | undefined>(Endpoints.getItemByTagCode(tagCode), undefined)
  if (data !== undefined) {
    return <Redirect to={{ pathname: `/items/${data.id}` }} />
  } else if (error !== undefined) {
    return <Redirect to={{ pathname: `/add`, search: `?tag=${tagCode}` }} />
  } else {
    return <></>
  }
}
