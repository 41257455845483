import React, { useMemo } from 'react'
import { ReactComponent as ReaktorLogo } from '../../assets/reaktor_r.svg'
import styled from 'styled-components'
import { preventingDefault } from '../preventingDefault'

const MenuContainerDiv = styled.div`
  border-bottom: 1px solid ${props => props.theme.dividerColor};
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 5;
  display: flex;
  align-self: center;
  align-items: center;
  padding: 0;
  background-color: ${props => props.theme.menuBackgroundColor};
`

const Logo = styled.a`
  cursor: pointer;
  margin-left: 25px;
  width: 25px;
  height: 40px;
  path {
    fill: ${props => props.theme.logoColor};
  }
  text-decoration: none;
`

type Props = {
  logoHref: string
  onLogoClick?: () => void
}

export const MenuContainer: React.FC<Props> = ({ logoHref, onLogoClick, children }) => {
  const handleLogoClick = useMemo(() => (onLogoClick !== undefined ? preventingDefault(onLogoClick) : undefined), [
    onLogoClick,
  ])
  return (
    <MenuContainerDiv>
      <Logo href={logoHref} onClick={handleLogoClick}>
        <ReaktorLogo />
      </Logo>
      {children}
    </MenuContainerDiv>
  )
}
