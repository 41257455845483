import React from 'react'
import { Submenu } from '../components/Submenu/Submenu'
import { Tab } from '../components/Submenu/Tabs'
import { Category, SearchResults } from '../model'
import { ListingPage } from './ListingPage/ListingPage'

export interface ReaktorPageProps {
  searchResults: SearchResults
  loading: boolean
  onActiveCategoriesChange: (activeCategories: Category[]) => void
  onTabChange: (tab: Tab) => void
}

export const ReaktorPage: React.FC<ReaktorPageProps> = (props: ReaktorPageProps) => {
  return (
    <>
      <Submenu activeTab={'reaktor'} onTabChange={props.onTabChange} />
      <ListingPage
        searchResults={props.searchResults}
        loading={props.loading}
        onActiveCategoriesChange={props.onActiveCategoriesChange}
      />
    </>
  )
}
