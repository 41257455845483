import React, { useState, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Section } from '../../components/Section/Section'
import { preventingDefault } from '../../components/preventingDefault'
import { post, Endpoints } from '../../Network'
import { Label, LabelTitle } from '../../components/Label'
import { TextInput } from '../../components/TextInput'
import { TextArea } from '../../components/TextArea'
import { Button } from '../../components/Button/Button'
import { useNotifyLoggedOut } from '../../loginState'

const usingValue = (handler: (value: string) => void) => (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
): void => handler(event.target.value)

const Form = styled.form`
  display: block;
  width: 100%;
  max-width: ${props => props.theme.itemPageMaxWidth};
  margin-left: auto;
  margin-right: auto;
`

export const LoggedOutReportForm: React.FC = () => {
  const { tagCode } = useParams<{ tagCode: string }>()
  const notifyLoggedOut = useNotifyLoggedOut()
  const [state, setState] = useState<'initialMessage' | 'form' | 'sending' | 'thankYou'>('initialMessage')
  const [message, setMessage] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [humanChallengeResponse, setHumanChallengeResponse] = useState('')

  const submitForm = useMemo(
    () =>
      preventingDefault(() => {
        setState('sending')
        post(
          Endpoints.sendGearFoundNotification(),
          {
            message,
            name,
            email,
            phone,
            tagCode,
            humanChallenge: {
              response: humanChallengeResponse,
            },
          },
          notifyLoggedOut,
        ).then(
          () => {
            setState('thankYou')
          },
          e => {
            // The sending of the notification will only fail if there's a network problem,
            // since we're using fetch, so let's just stay in the form in that case so that
            // the user won't think it's weird they succeeded sending when there's no
            // internet connection
            setState('form')
          },
        )
      }),
    [message, name, email, phone, tagCode, humanChallengeResponse, notifyLoggedOut],
  )

  const humanChallengeElementRef = useRef<HTMLInputElement>(null)

  const validateAndSetHumanChallengeResponse = useMemo(
    () => (value: string) => {
      setHumanChallengeResponse(value)
      const { current } = humanChallengeElementRef

      if (current) {
        current.setCustomValidity(
          value === '' || value.toLowerCase() === 'five' || value === '5' ? '' : 'This is not the correct answer',
        )
      }
    },
    [],
  )

  switch (state) {
    case 'initialMessage':
      return (
        <Section title="Dear finder, this belongs to someone at Reaktor." expandable={false} expanded={false}>
          <p>
            <button onClick={preventingDefault(() => setState('form'))}>Send a message to the owner</button>
          </p>
          <p>You are also welcome to drop the item at our reception and have a cup of coffee.</p>
          <p>
            Yliopistonkatu 4, 00100 Helsinki
            <br />
            Mon-Fri 8.30-16.30
          </p>
        </Section>
      )
    case 'form':
      return (
        <Section title="Send a message to the owner" expandable={false} expanded={false}>
          <Form onSubmit={submitForm}>
            <Label>
              <LabelTitle>Message</LabelTitle>
              <TextArea autoComplete="none" value={message} onChange={usingValue(setMessage)} />
            </Label>
            <Label>
              <LabelTitle>Name</LabelTitle>
              <TextInput autoComplete="name" value={name} onChange={usingValue(setName)} type="text" />
            </Label>
            <Label>
              <LabelTitle>Email</LabelTitle>
              <TextInput autoComplete="email" value={email} onChange={usingValue(setEmail)} type="email" />
            </Label>
            <Label>
              <LabelTitle>Phone</LabelTitle>
              <TextInput autoComplete="tel" value={phone} onChange={usingValue(setPhone)} type="tel" />
            </Label>
            <Label>
              <LabelTitle>What is three plus two (just checking that you're a human)</LabelTitle>
              <TextInput
                ref={humanChallengeElementRef}
                autoComplete="none"
                required={true}
                value={humanChallengeResponse}
                onChange={usingValue(validateAndSetHumanChallengeResponse)}
              />
            </Label>
            <p>
              Your message and contact information will be sent to the owner. We won't store your information, or
              disclose it to third parties.
            </p>
            <Button type="submit">Send</Button>
          </Form>
        </Section>
      )
    case 'sending':
      return (
        <Section title="Send a message to the owner" expandable={false} expanded={false}>
          Sending
        </Section>
      )
    case 'thankYou':
      return (
        <Section title="Thank you" expandable={false} expanded={false}>
          Your message was sent to the owner. They will be in contact with you soon.
        </Section>
      )
  }
}
