import React from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  expanded: boolean
  expandable: boolean
  onClick?: () => void
  children?: React.ReactNode
}

const Container = styled.div`
  margin-top: ${props => props.theme.spacing(2)};
  padding-bottom: ${props => props.theme.spacing(2)};
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  height: 38px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  margin-bottom: ${props => props.theme.spacing(1)};
`

const Chevron = styled.div<{ rotate: number }>`
  width: 24px;
  height: 38px;
  background-image: url(/images/chevron-right.svg);
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: left center;
  filter: hue-rotate(90deg);
  transform: rotate(${props => (props.rotate ? '-90deg' : '90deg')});
  transition-duration: 200ms;
  transition-property: transform;
`

const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.2em;
  margin-left: ${props => props.theme.spacing(0)};
`

export const Section: React.FC<Props> = (props: Props) => {
  return (
    <Container>
      <Title onClick={props.onClick}>
        <Text>{props.title}</Text>
        {props.expandable && <Chevron rotate={props.expanded ? 1 : 0} />}
      </Title>
      {props.children}
    </Container>
  )
}
