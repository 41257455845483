import React, { useState } from 'react'
import { Section } from '../../components/Section/Section'
import { GearItem, Loan } from '../../model'
import { ActivityItem, LoanHistoryItem } from './ActivityItem'

interface Props {
  loans: Loan[]
  item: GearItem
}

const expandLimit = 2

export const ActivityHistory: React.FC<Props> = (props: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  const items: ActivityItem[] = props.loans
    .map((loan: Loan): ActivityItem[] => {
      const borrowed: ActivityItem = {
        id: loan.id + 'borrow',
        date: loan.start,
        person: loan.created_by,
        text: 'Borrowed the item',
      }
      if (loan.end && loan.updated_by) {
        const returned: ActivityItem = {
          id: loan.id + 'return',
          date: loan.end,
          person: loan.updated_by,
          text: 'Returned the item',
        }
        return [returned, borrowed]
      } else {
        return [borrowed]
      }
    })
    .flat()
    .concat([
      {
        id: 'created',
        date: props.item.created_at,
        person: props.item.created_by,
        text: 'Created the item',
      },
    ])

  const onExpand = () => {
    setExpanded(!expanded)
  }

  const loans = expanded ? items : items.slice(0, expandLimit)

  return (
    <Section title="Activity" expanded={expanded} onClick={onExpand} expandable={items.length > expandLimit}>
      {loans.map(activityItem => (
        <LoanHistoryItem key={activityItem.id} activityItem={activityItem} />
      ))}
    </Section>
  )
}
