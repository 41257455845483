import * as t from 'io-ts'
import { validator } from 'io-ts-validator'
import { useEffect, useState } from 'react'
import {
  CategoryCodec,
  GearItemCodec,
  LoanAndItemCodec,
  LoanCodec,
  LocationCodec,
  PersonCodec,
  SearchResultsCodec,
  UserCodec,
} from './model'
import { useNotifyLoggedOut } from './loginState'

interface Endpoint {
  url: string
  codec: any // TODO: definition
}

export const Endpoints = {
  allItems: (): Endpoint => ({
    url: '/api/v1/item/',
    codec: t.array(GearItemCodec),
  }),
  categories: (): Endpoint => ({
    url: `/api/v1/category/`,
    codec: t.array(CategoryCodec),
  }),
  postCategory: (): Endpoint => ({
    url: '/api/v1/category',
    codec: CategoryCodec,
  }),
  postItem: (): Endpoint => ({
    url: '/api/v1/item',
    codec: GearItemCodec,
  }),
  putItem: (id: string): Endpoint => ({
    url: `/api/v1/item/${id}`,
    codec: GearItemCodec,
  }),
  postItemPhoto: (id: number): Endpoint => ({
    url: `/api/v1/item/${id}/photo`,
    codec: GearItemCodec,
  }),
  postLoan: (id: number): Endpoint => ({
    url: `/api/v1/loans/item/${id}`,
    codec: t.any,
  }),
  postReturnLoan: (loanId: number): Endpoint => ({
    url: `/api/v1/loans/${loanId}/return`,
    codec: t.any,
  }),
  postSearch: (): Endpoint => ({
    url: '/api/v1/search',
    codec: SearchResultsCodec,
  }),
  getItem: (id: string): Endpoint => ({
    url: `/api/v1/item/${id}`,
    codec: GearItemCodec,
  }),
  getItemByTagCode: (tagCode: string): Endpoint => ({
    url: `/api/v1/tagCode/${tagCode}`,
    codec: GearItemCodec,
  }),
  getLoans: (id: number): Endpoint => ({
    url: `/api/v1/loans/item/${id}`,
    codec: t.array(LoanCodec),
  }),
  getLoansByUser: (): Endpoint => ({
    url: `/api/v1/loans/user`,
    codec: t.array(LoanAndItemCodec),
  }),
  getPeople: (): Endpoint => ({
    url: '/api/v1/people',
    codec: t.array(PersonCodec),
  }),
  getLocations: (): Endpoint => ({
    url: '/api/v1/locations',
    codec: t.array(LocationCodec),
  }),
  getUser: (): Endpoint => ({
    url: `/api/v1/user`,
    codec: UserCodec,
  }),
  deleteItem: (id: number): Endpoint => ({
    url: `/api/v1/item/${id}`,
    codec: t.boolean,
  }),
  deleteItemPhoto: (id: number): Endpoint => ({
    url: `/api/v1/item/${id}/photo`,
    codec: GearItemCodec,
  }),
  sendGearFoundNotification: (): Endpoint => ({
    url: '/api/v1/gearFound',
    codec: t.unknown,
  }),
}

export const useGet = <T>(endpoint: Endpoint, defaultValue: T): GetHook<T> => {
  const revalidate = () => {
    doIt()
  }
  const [state, setState] = useState<{ loading: boolean; data: T; error: string | undefined; revalidate: () => void }>({
    loading: true,
    data: defaultValue,
    error: undefined,
    revalidate,
  })
  const notifyLoggedOut = useNotifyLoggedOut()

  const doIt = async () => {
    try {
      const response = await fetch(endpoint.url, {
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      const responseJson = await response.json()

      if (response.status >= 200 && response.status <= 299) {
        const result = validator(endpoint.codec).decodeSync(responseJson)
        // TODO: type via io-ts
        setState({ loading: false, data: result as T, error: undefined, revalidate })
      } else if (response.status === 401) {
        notifyLoggedOut()
      } else {
        setState({
          loading: false,
          data: defaultValue,
          error: `Error ${response.status}, ${responseJson.message}`,
          revalidate,
        })
      }
    } catch (error) {
      console.error(error)
      const message = error instanceof Error ? error.message : `unknown error "${error}"`
      setState({ loading: false, data: defaultValue, error: message, revalidate })
    }
  }

  useEffect(() => {
    revalidate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return state
}

export interface GetHook<T> {
  loading: boolean
  data: T
  error: string | undefined
  revalidate: () => void
}

const changeWithMethod = async <S, T>(endpoint: Endpoint, method: string, json: S, notifyLoggedOut: () => void) => {
  const response = await fetch(endpoint.url, {
    credentials: 'same-origin',
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(json),
  })

  if (response.status >= 200 && response.status <= 299) {
    const responseJson = await response.json()
    const result = validator(endpoint.codec).decodeSync(responseJson)
    return result as T
  } else if (response.status === 401) {
    // This sucks when doing a change, it's lost
    notifyLoggedOut()
    throw Error('Authentication required')
  } else {
    const body = response.body ? await response.text() : ''
    throw Error(`${response.status}: ${body}`)
    // setError(`Error ${response.status}`)
  }
}

export const post = async <S, T>(endpoint: Endpoint, json: S, notifyLoggedOut: () => void) => {
  return changeWithMethod<S, T>(endpoint, 'POST', json, notifyLoggedOut)
}

export const put = async <S, T>(endpoint: Endpoint, json: S, notifyLoggedOut: () => void) => {
  return changeWithMethod<S, T>(endpoint, 'PUT', json, notifyLoggedOut)
}

export const del = async (endpoint: Endpoint, notifyLoggedOut: () => void) => {
  return changeWithMethod<any, any>(endpoint, 'DELETE', {}, notifyLoggedOut)
}

export const postFile = async <T>(endpoint: Endpoint, file: File) => {
  const formData = new FormData()
  formData.append('photo', file)
  const response = await fetch(endpoint.url, {
    method: 'POST',
    body: formData,
  })
  if (response.status >= 200 && response.status <= 299) {
    const responseJson = await response.json()
    const result = validator(endpoint.codec).decodeSync(responseJson)
    return result as T
  } else {
    throw Error()
  }
}

export type LoginResult = Readonly<
  | { type: 'error'; displayMessage: string; statusCode: number }
  | { type: 'success' }
  | { type: 'interactionRequired'; url: URL }
>

export const login = async (): Promise<LoginResult> => {
  const response = await fetch('/login', {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })

  if (response.status >= 200 && response.status <= 299) {
    return { type: 'success' }
  } else if (response.status === 401) {
    const { authenticationUrl } = await response.json()
    return { type: 'interactionRequired', url: new URL(authenticationUrl) }
  } else {
    const displayMessage = response.body ? await response.text() : ''
    return { type: 'error', statusCode: response.status, displayMessage }
  }
}

export const checkIsLoggedIn = async (): Promise<boolean> => {
  const response = await fetch('/login/status', {
    credentials: 'same-origin',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })

  if (response.status === 200) {
    const { isLoggedIn } = await response.json()
    return isLoggedIn
  } else {
    return false
  }
}

export type GearFoundNotification = Readonly<{
  tagCode: string
  message: string
  name: string
  email: string
  phone: string
  humanChallenge: Readonly<{
    response: string
  }>
}>
