import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as CameraIcon } from '../../assets/camera.svg'
import { cloudinaryFitImage } from '../../lib/cloudinary'
import { GearItem } from '../../model'

const ItemContainer = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${props => props.theme.breakpoint}) {
    flex-direction: row;
  }
`

const imageWidth = 130
const imageHeight = 150

const Image = styled.img`
  display: div;
  width: ${imageWidth}px;
  height: ${imageHeight}px;
  object-fit: cover;

  @media (max-width: ${props => props.theme.breakpoint}) {
    width: 60px;
    height: 60px;
  }
  border-radius: ${props => props.theme.imageBorderRadius};
`

const Title = styled.div`
  margin-top: ${props => props.theme.spacing(0.5)};
  color: ${props => props.theme.textColor};
  width: 130px;
  max-width: 100%;

  @media (max-width: ${props => props.theme.breakpoint}) {
    padding-left: 16px;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${props => props.theme.breakpoint}) {
    width: 100%;
  }
`

const NoImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${imageWidth}px;
  height: ${imageHeight}px;
  background-color: ${props => props.theme.imageBlankBackground};

  @media (max-width: ${props => props.theme.breakpoint}) {
    width: 60px;
    height: 60px;
  }

  svg {
    width: 40px;
    height: 40px;

    @media (max-width: ${props => props.theme.breakpoint}) {
      width: 15px;
      height: 16px;
    }
  }
`

export interface Props {
  item: GearItem
}

export const Item: React.FC<Props> = (props: Props) => {
  const imageUrl = () => {
    if (props.item.photos.length > 0) {
      return cloudinaryFitImage(props.item.photos[0].url, imageWidth, imageHeight)
    } else {
      return undefined
    }
  }

  const hasPhotos = props.item.photos.length > 0

  return (
    <StyledLink to={`/items/${props.item.id}`}>
      <ItemContainer>
        {hasPhotos && <Image src={imageUrl()} />}
        {!hasPhotos && (
          <NoImage>
            <CameraIcon />
          </NoImage>
        )}
        <Title>{props.item.name}</Title>
      </ItemContainer>
    </StyledLink>
  )
}
