import React from 'react'
import styled from 'styled-components'

const B = styled.button`
  position: relative;
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
  margin-top: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(2)};
  padding-left: ${props => props.theme.spacing(5)};
  padding-right: ${props => props.theme.spacing(5)};
  height: ${props => props.theme.buttonHeight};
  border-radius: ${props => props.theme.spacing(1)};
  background-color: ${props => props.theme.buttonOkColor};
  color: ${props => props.theme.buttonOkTextColor};
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);

  transition: all 0.2s ease;

  :hover {
    background-color: ${props => props.theme.buttonOkColorHover};
  }
`

const BNormal = styled(B)`
  background-color: ${props => props.theme.buttonNormalColor};
  color: ${props => props.theme.buttonNormalTextColor};

  :hover {
    background-color: ${props => props.theme.buttonNormalColorHover};
  }
`

const BDelete = styled(B)`
  background-color: transparent;
  color: ${props => props.theme.buttonDeleteColor};

  :hover {
    background-color: ${props => props.theme.buttonDeleteColor};
    color: ${props => props.theme.buttonOkTextColor};
  }
`

const Spinner = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;

  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);

  :after {
    content: ' ';
    display: block;
    width: 16px;
    height: 16px;
    margin: 4px;
    margin-top: 2px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  active?: boolean
}

export const Button: React.FC<Props> = ({ active = false, children, ...props }) => {
  return (
    <B {...props}>
      {active && <Spinner />}
      {children}
    </B>
  )
}

export const DeleteButton: React.FC<Props> = ({ active = false, children, ...props }) => {
  return (
    <BDelete {...props}>
      {active && <Spinner />}
      {children}
    </BDelete>
  )
}

export const ButtonNormal: React.FC<Props> = ({ active = false, children, ...props }) => {
  return (
    <BNormal {...props}>
      {active && <Spinner />}
      {children}
    </BNormal>
  )
}
