import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { ContentContainer } from './components/ContentContainer/ContentContainer'
import { InstructionsPage } from './pages/InstructionsPage/InstructionsPage'
import { ItemEditPage } from './pages/ItemEditPage/ItemEditPage'
import { ItemViewPage } from './pages/ItemViewPage/ItemViewPage'
import { Menu } from './components/Menu/Menu'
import { MenuContainer } from './components/MenuContainer/MenuContainer'
import { isTab, parseTab, Tab } from './components/Submenu/Tabs'
import { Category, CountrySelection, Location, SearchRequest, SearchResults, User } from './model'
import { Endpoints, post, useGet } from './Network'
import { ScrollToTop } from './ScrollToTop'
import { LoginStateHandler } from './LoginStateHandler'
import { theme, Theme } from './Theme'
import { ScanLinkHandler } from './ScanLinkHandler'
import { LoggedOutPage } from './pages/LoggedOutPage/LoggedOutPage'
import { AllPage } from './pages/AllPage'
import { parse } from 'query-string'
import { useNotifyLoggedOut } from './loginState'
import { BorrowablePage } from './pages/BorrowablePage'
import { ReaktorPage } from './pages/ReaktorPage'
import { MyGearPage } from './pages/MyGearPage/MyGearPage'
import { SearchPage } from './pages/SearchPage'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => (props.theme as Theme).backgroundColor};
    color: ${props => (props.theme as Theme).textColor};
  }
`
const defaultCountrySelection: CountrySelection = 'globe'

const AppContent: React.FC = () => {
  const history = useHistory()
  const userGet = useGet<User | null>(Endpoints.getUser(), null)
  const categoriesGet = useGet<Category[]>(Endpoints.categories(), [])
  const locationsGet = useGet<Location[]>(Endpoints.getLocations(), [])
  const [searchTerms, setSearchTerms] = useState<string>('')
  const [uiCountry, setUICountry] = useState<CountrySelection>(defaultCountrySelection)
  const location = useLocation()
  const maybeTab = parseTab(location.pathname)
  const [activeTab, setActiveTab] = useState<Tab>(isTab(maybeTab) ? maybeTab : 'borrowable')
  const [loadingItems, setLoadingItems] = useState(false)

  const [searchResults, setSearchResults] = useState<SearchResults>({
    items: [],
    categories: [],
    request: {
      country: defaultCountrySelection,
      categories: [],
      terms: '',
      loanable: null,
      contact: null,
      personal: null,
    },
  })

  const notifyLoggedOut = useNotifyLoggedOut()

  function getActiveCategories(): Category[] {
    const queryString = parse(location.search)

    const activeCategories = !queryString.t
      ? []
      : categoriesGet.data?.filter(c => c.name.toLowerCase() === (queryString.t as string).toLowerCase()) || []

    return activeCategories
  }

  async function postSearch(
    maybeTab: string,
    country: CountrySelection,
    activeCategories: Category[],
    searchTerms = '',
  ) {
    if (!isTab(maybeTab)) return

    const request: SearchRequest = {
      terms: maybeTab === 'search' ? searchTerms : '',
      loanable: maybeTab === 'borrowable' ? true : null,
      personal: maybeTab === 'reaktor' ? false : null,
      contact: maybeTab === 'mygear' ? userGet.data?.uid || null : null,
      country,
      categories: activeCategories.map(c => c.id),
    }
    try {
      setLoadingItems(true)
      const results = await post<SearchRequest, SearchResults>(Endpoints.postSearch(), request, notifyLoggedOut)

      setSearchResults(results)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingItems(false)
    }
  }

  useEffect(() => {
    if (
      categoriesGet.loading ||
      userGet.loading ||
      !userGet.data ||
      categoriesGet.data.length === 0 ||
      locationsGet.loading
    )
      return
    const maybeTab = parseTab(location.pathname)
    if (!isTab(maybeTab)) return

    postSearch(maybeTab, userGet.data.defaultLocation.country, getActiveCategories())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesGet.loading, userGet.loading, locationsGet.loading, userGet.data])

  useEffect(() => {
    if (userGet.data) {
      setUICountry(userGet.data.defaultLocation.country)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGet.data])

  useEffect(() => {
    const maybeTab = parseTab(location.pathname)

    if (!isTab(maybeTab)) return

    setActiveTab(maybeTab)
    if (maybeTab !== 'search') {
      setSearchTerms('')
    }

    if (!userGet.data || categoriesGet.loading || locationsGet.loading) return

    postSearch(maybeTab, uiCountry, getActiveCategories(), maybeTab === 'search' ? searchTerms : '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search])

  const error = categoriesGet.error || userGet.error || locationsGet.error || categoriesGet.error
  if (error) {
    return <div>Error, try reloading in a few seconds. {JSON.stringify(error)}</div>
  }

  if (userGet.loading || !userGet.data || categoriesGet.loading || locationsGet.loading) {
    return <div>Loading info ...</div>
  }

  const onLogoClick = () => {
    changeTab(`borrowable`)
  }

  const onCategoryNavigation = (category: Category) => {
    onActiveCategoriesChange([category])
  }

  const onSearchTermsChange = (terms: string) => {
    if (terms === '' && searchTerms === '') return

    setSearchTerms(terms)

    // NOTE: prev version checked the length as well
    if (terms.length > 0 && terms.length < 3) return

    if (activeTab !== 'search') {
      changeTab(`search`)
      return
    }

    postSearch('search', uiCountry, getActiveCategories(), terms)
  }

  const onActiveCountryChange = (country: CountrySelection) => {
    setUICountry(country)
    postSearch(activeTab, country, getActiveCategories(), searchTerms)
  }

  const changeUrl = (tab: Tab, categories: Category[]) => {
    const url = categories.length === 0 ? `/${tab}` : `/${tab}?t=${categories[0].name}`
    history.push(url)
  }

  const changeTab = (tab: Tab) => {
    setActiveTab(tab)
    changeUrl(tab, [])
  }

  const onActiveCategoriesChange = (categories: Category[]) => {
    changeUrl(activeTab, categories)
  }

  return (
    <>
      <Menu
        searchTerm={searchTerms}
        activeCountry={uiCountry}
        onSearch={onSearchTermsChange}
        logoHref="/"
        onLogoClick={onLogoClick}
        onActiveCountryChange={onActiveCountryChange}
      />
      <ContentContainer>
        <Switch>
          <Route path={'/reaktor'} exact={true}>
            <ReaktorPage
              searchResults={searchResults}
              loading={loadingItems}
              onActiveCategoriesChange={onActiveCategoriesChange}
              onTabChange={changeTab}
            />
          </Route>
          <Route path={['/', '/borrowable']} exact={true}>
            <BorrowablePage
              searchResults={searchResults}
              loading={loadingItems}
              onActiveCategoriesChange={onActiveCategoriesChange}
              onTabChange={changeTab}
            />
          </Route>
          <Route path="/all" exact={true}>
            <AllPage
              searchResults={searchResults}
              loading={loadingItems}
              onActiveCategoriesChange={onActiveCategoriesChange}
              onTabChange={changeTab}
            />
          </Route>
          <Route path="/mygear" exact={true}>
            <MyGearPage
              searchResults={searchResults}
              loading={loadingItems}
              onActiveCategoriesChange={onActiveCategoriesChange}
              onTabChange={changeTab}
            />
          </Route>
          <Route path="/search" exact={true}>
            <SearchPage
              searchResults={searchResults}
              loading={loadingItems}
              onActiveCategoriesChange={onActiveCategoriesChange}
            />
          </Route>
          <Route path="/items/:itemId/edit">
            <ItemEditPage
              update={categoriesGet.revalidate}
              categories={categoriesGet.data}
              user={userGet.data}
              locations={locationsGet.data}
            />
          </Route>
          <Route path="/items/:itemId">
            <ItemViewPage
              categories={categoriesGet.data}
              onCategoryNavigation={onCategoryNavigation}
              user={userGet.data}
              locations={locationsGet.data}
            />
          </Route>
          <Route path="/add" exact={true}>
            <ItemEditPage
              update={categoriesGet.revalidate}
              categories={categoriesGet.data}
              user={userGet.data}
              locations={locationsGet.data}
            />
          </Route>
          <Route path="/instructions" exact={true}>
            <InstructionsPage />
          </Route>
          <Route path="/:tagCode([A-Z][0-9]{3,6})" exact={true}>
            <ScanLinkHandler />
          </Route>
        </Switch>
      </ContentContainer>
    </>
  )
}

export const CheckingLoginStatusView: React.FC = () => (
  <>
    <MenuContainer logoHref="/" />
    <ContentContainer>Checking login status...</ContentContainer>
  </>
)

export const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ScrollToTop />
        <LoginStateHandler
          loggedInView={<AppContent />}
          loggedOutView={<LoggedOutPage />}
          checkingStatusView={<CheckingLoginStatusView />}
        />
      </ThemeProvider>
    </Router>
  )
}
