import React from 'react'
import styled from 'styled-components'
import { Category, SearchResults } from '../../model'
import { LoansByUser } from './LoansByUser'
import { MyListing } from './MyListing'

const Container = styled.div`
  margin-top: ${props => props.theme.spacing(4)};
`

export interface MyGearProps {
  searchResults: SearchResults
  loading: boolean
  onActiveCategoriesChange: (activeCategories: Category[]) => void
}

export const MyGear: React.FC<MyGearProps> = (props: MyGearProps) => {
  const activeCategories = props.searchResults.categories.filter(c =>
    props.searchResults.request.categories.includes(c.id),
  )

  return (
    <Container>
      <MyListing
        items={props.searchResults.items}
        loading={props.loading}
        activeCategories={activeCategories}
        categories={props.searchResults.categories}
        onActiveCategoriesChange={props.onActiveCategoriesChange}
      />
      <LoansByUser />
    </Container>
  )
}
